<template>

    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10 results">

        <div class="results__title grey darken-4 elevation-15 mb-10">

            <v-card-title class="title"> {{ data.question }} </v-card-title>

            <v-divider></v-divider>

            <v-card-actions v-if="results.totalAnswers.total > 0">

                <v-card-text class="text-left text-weight-bold subtitle-1">

                    <span class="pr-2">Results:</span>

                    <span class="blue--text">
                         {{ results.totalAnswers.total }}
                    </span>

                </v-card-text>

                <v-spacer></v-spacer>

                <v-btn
                    @click.native="generateReport"
                    color="primary"
                    :loading="isGenerating"
                >
                     Generate Report
                    <v-icon right>fa-chart-bar</v-icon>

                </v-btn>

                <v-btn
                    @click.native="clearPoll"
                    color="primary"
                    :loading="isClearing"
                >
                     Clear Poll
                    <v-icon right>fa-trash-alt</v-icon>

                </v-btn>

            </v-card-actions>

            <v-card-actions v-else> This Poll has not results </v-card-actions>

        </div>

        <v-simple-table dark v-if="results.totalAnswers.total > 0">

            <template v-slot:default>

                <thead>

                    <tr>

                        <th class="text-left">ANSWER</th>

                        <th class="text-left">TOTAL</th>

                        <th class="text-left">PERCENTAGE</th>

                        <th class="text-right">DISTINCTION</th>

                    </tr>

                </thead>

                <tbody>

                    <tr v-for="result in results.answers" :key="result.id">

                        <td> {{ result.answer }} </td>

                        <td> {{ result.responses.count }} </td>

                        <td> {{ result.responses.percentage }}% </td>

                        <td class="text-xs-right">

                            <span
                                v-if="results.totalAnswers.totalBy.length <= 1"
                            >
                                 No distinctive
                            </span>

                            <table v-else class="wide">

                                <tr>

                                    <th>Distintive</th>

                                    <th>Total</th>

                                    <th>Percentage</th>

                                </tr>

                                <tr
                                    v-for="(subResult, subResultIndex) in result.responses.byDistinctive"
                                    :key="subResultIndex"
                                >

                                    <td>

                                        <strong>
                                             {{ subResult.distinctive }}
                                        </strong>

                                    </td>

                                    <td>{{ subResult.count }}</td>

                                    <td>{{ subResult.percentage }}%</td>

                                </tr>

                            </table>

                        </td>

                    </tr>

                </tbody>

            </template>

        </v-simple-table>

        <div v-if="results.totalAnswers.totalBy.length > 1">

            <v-divider class="mt-5 mb-5"></v-divider>

            <v-flex xs12 sm12 md6 class="mb-4">

                <pie-chart
                    v-if="loaded"
                    :data="dataByDistinctive"
                    :options="optionsPie"
                    :title='"Data by DIstinctive"'
                ></pie-chart>

            </v-flex>

            <v-divider class="mt-5 mb-5"></v-divider>

            <v-flex xs12 sm12 md6 class="mb-4">

                <pie-chart
                    v-if="loaded"
                    :data="dataByAnswer"
                    :options="optionsPie"
                    :title='"Data by Answer"'
                ></pie-chart>

            </v-flex>

            <v-divider class="mt-5 mb-5"></v-divider>

            <v-flex xs12 sm12 md6>

                <bar-chart
                    v-if="loaded"
                    :data="dataByAnswerAndDistinctive"
                    :options="optionsBar"
                    :title='"Data by Answer and Distintive"'
                ></bar-chart>

            </v-flex>

        </div>

    </v-card>

</template>

<script>
import _ from 'lodash';
import PieChart from '@/components/PieChart.vue';
import BarChart from '@/components/BarChart.vue';

import Constants from '@/constants';

export default {
    name: 'Results',
    props: ['id', 'data'],
    data: () => ({
        loaded: false,
        optionsPie: {
            responsive: false,
            maintainAspectRatio: false,
            legend: {
                fullWidth: true
            }
        },
        optionsBar: {
            responsive: false,
            maintainAspectRatio: false,
            legend: {
                position: 'bottom'
            },
            scales: {
                x: {
                    stacked: false,
                    display: false,
                    gridLines: {
                        offsetGridLines: true
                    }
                }
            }
        },
        dataByDistinctive: {
            labels: [],
            datasets: [{
                label: 'Responses',
                backgroundColor: [],
                data: []
            }]
        },
        dataByAnswer: {
            labels: [],
            datasets: [{
                label: 'Responses',
                backgroundColor: [],
                data: []
            }]
        },
        dataByAnswerAndDistinctive: {
            labels: [],
            datasets: [],
            xLabels: false
        },
        isClearing: false,
        isGenerating: false
    }),
    computed: {
        results() {
            return this.$store.getters['poll/results'];
        },
        loading() {
            return this.$store.getters['poll/loading'];
        }
    },
    mounted() {
        this.processData();
    },
    methods: {
        generateRandomColor() {
            return "#000000".replace(/0/g, function() {
                return (~~(Math.random() * 16)).toString(16);
            });
        },
        processData() {
            let distinctives = [];
            let tmpLabels = [];
            if (this.results.totalAnswers.total <=
                0) {
                return;
            }
            _.each(this.results.totalAnswers.totalBy, result => {
                this.dataByDistinctive.labels.push(result.distinctive);
                this.dataByDistinctive.datasets[0].data.push(result.count);
                this.dataByDistinctive.datasets[0].backgroundColor.push(this.generateRandomColor());
                distinctives.push(result.distinctive);
            });
            _.each(this.results.answers, answer => {
                this.dataByAnswer.labels.push(_.truncate(answer.answer));
                this.dataByAnswer.datasets[0].data.push(answer.responses.count);
                this.dataByAnswer.datasets[0].backgroundColor.push(this.generateRandomColor());
            });
            _.each(distinctives, distinctive => {
                this.dataByAnswerAndDistinctive.datasets.push({
                    label: distinctive,
                    backgroundColor: this.generateRandomColor(),
                    data: []
                });
            });
            _.each(this.results.answers, answer => {
                tmpLabels.push(_.truncate(answer.answer));
                _.each(this.dataByAnswerAndDistinctive.datasets, (dataset, i) => {
                    let found = _.find(answer.responses.byDistinctive, {
                        distinctive: dataset.label
                    });
                    if (typeof found === 'undefined') {
                        found = {
                            count: 0
                        };
                    }
                    this.dataByAnswerAndDistinctive.datasets[i].data.push(found.count);
                });
            });
            this.dataByAnswerAndDistinctive.labels = tmpLabels;
            this.$nextTick(() => {
                this.loaded = true;
            });
        },
        generateReport() {
            const token = this.$store.getters['auth/token'];
            const url = Constants.APP_URL + '/api/v1/i/polls/' + this.id + '/responses?token=' +
                token;
            window.open(url, '_blank');
        },
        clearPoll() {
            this.isClearing = true;
            this.$store.dispatch('poll/clecacaarPoll',
                this.id).then(() => {
                this.$store.dispatch('poll/getResults', this.id).then(() => {
                    this.isClearing = false;
                }).catch(() => {});
            }).catch(() => {});
        }
    },
    components: {
        PieChart,
        BarChart
    }
}
</script>

<style scoped lang="scss">
.results__title {
    border-radius: 4px;
}

.wide {
    width: 100%;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    border-spacing: 0;

    th,
    td {
        line-height: 22px;
    }

    th {
        text-transform: uppercase;
        border-bottom: thin solid rgba(255, 255, 255, 0.12);
    }

    td {
        text-align: center;
    }

    tr:not(:last-child) {
        td {
            border-bottom: thin solid rgba(255, 255, 255, 0.12);
        }
    }
}
</style>


<template>
    <v-stepper v-model="step">
        <v-stepper-header>
            <v-stepper-step
                :complete="step > 1"
                step="1"
                :editable="id !== null"
            >Basic Config</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
                :complete="step > 2"
                step="2"
                :editable="id !== null"
            >Answers</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-form ref="formBasic" v-model="formBasicValid">
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Application Title"
                                v-model="inputs.title"
                                :rules="[rules.required, rules.counter]"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-menu
                                v-model="menu.start_date"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="menu.start_date_formatted"
                                        label="Start Date"
                                        hint="MM/DD/YYYY format"
                                        persistent-hint
                                        prepend-icon="fa-calendar-alt"
                                        @blur="inputs.start_date = dateService.parseDate(menu.start_date_formatted)"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="inputs.start_date"
                                    no-title
                                    @input="menu.start_date = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-menu
                                v-model="menu.end_date"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="menu.end_date_formatted"
                                        label="End Date"
                                        hint="MM/DD/YYYY format"
                                        persistent-hint
                                        prepend-icon="fa-calendar-alt"
                                        @blur="inputs.end_date = dateService.parseDate(menu.end_date_formatted)"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="inputs.end_date"
                                    no-title
                                    @input="menu.end_date = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Question"
                                v-model="inputs.question"
                                :rules="[rules.required, rules.counter]"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-btn text :to="'/dashboard'">Cancel</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="primary"
                                @click="saveBasic"
                                :loading="isSavingBasic"
                            >{{ id !== null ? 'Save & Continue' : 'Save' }}</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
                <v-card>
                    <v-form ref="formAnswer" v-model="formAnswerValid">
                        <v-card-text class="pl-5 pr-5">
                            <v-row align="center" justify="space-between">
                                <v-col>
                                    <v-text-field
                                        label="Answer"
                                        v-model="answer.answer"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        color="primary"
                                        @click="addAnswer"
                                        :loading="isSavingAnswer"
                                    >Add</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-form>
                </v-card>

                <br>
                <v-divider light></v-divider>
                <br>

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-center">ANSWER</th>
                            <th class="text-center">ACTIONS</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="tempAnswer in tempAnswers" :key="tempAnswer.answer">
                            <td class="text-center">{{ tempAnswer.answer }}</td>
                            <td class="text-center">
                                <v-btn
                                    @click.native="deleteAnswer(tempAnswer.id)"
                                    icon="icon"
                                ><v-icon class="text-info">fa-trash-alt</v-icon></v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <v-row align="center" justify="start">
                    <v-col>
                        <v-btn text @click="step = 1">Back</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
    import _ from 'lodash';
    import DateService from '@/services/date.service';

    export default {

        name: 'Edit',

        props: ['id', 'data'],

        data: () => ({
            step: 1,
            dateService: DateService,
            formBasicValid: true,
            formAnswerValid: true,
            inputs: {
                title: '',
                start_date: new Date().toISOString().substr(0, 10),
                end_date: DateService.addDays(new Date(), 15).toISOString().substr(0, 10),
                question: ''
            },
            menu: {
                start_date: false,
                start_date_formatted: DateService.formatDate(new Date().toISOString().substr(0, 10)),
                end_date: false,
                end_date_formatted: DateService.formatDate(DateService.addDays(new Date(), 15).toISOString().substr(0, 10))
            },
            rules: {
                required: value => !!value || 'Required',
                counter: value => value.length >= 5 || 'Min 5 characters'
            },
            answer: {
                answer: ''
            },
            tempAnswers: [],
            isSavingBasic: false,
            isSavingAnswer: false
        }),

        computed: {

            answers() {
                return this.id !== null ? this.$store.getters['poll/answers'] : {};
            }
        },

        watch: {

            'inputs.start_date'() {
                this.menu.start_date_formatted = DateService.formatDate(this.inputs.start_date);
            },

            'inputs.end_date'() {
                this.menu.end_date_formatted = DateService.formatDate(this.inputs.end_date);
            }
        },

        mounted() {
            this.isSavingBasic = false;
            this.isSavingAnswer = false;

            if (localStorage.getItem('flowUpdate') !== null) {
                this.step = 2;
                localStorage.removeItem('flowUpdate');
            }

            if (this.id !== null) {
                this.setInputValues();
            }
        },

        methods: {

            setInputValues() {
                _.each(this.inputs, (v, k) => {
                    if (k.indexOf('date') > -1) {
                        this.inputs[k] = new Date(this.data[k]).toISOString().substr(0, 10);
                    } else {
                        this.inputs[k] = this.data[k];
                    }
                });

                this.tempAnswers = _.cloneDeep(this.answers);
            },

            saveBasic() {
                this.isSavingBasic = true;
                this.$refs.formBasic.validate();

                if (this.formBasicValid) {
                    const action = this.id === null ? 'new' : 'update';
                    const inputs = _.cloneDeep(this.inputs);
                    inputs.start_date += ' 00:00:00';
                    inputs.end_date += ' 23:59:59';
                    const data = this.id === null ? inputs : {
                        id: this.id,
                        data: inputs
                    };

                    this.$store.dispatch('poll/' + action, data).then(data => {
                        this.$store.dispatch('poll/get', data.id).then(() => {
                            this.isSavingBasic = false;

                            if (action === 'new') {
                                localStorage.setItem('flowUpdate', true);
                                this.$router.replace({
                                    name: 'poll',
                                    params: {
                                        id: data.id,
                                        selectedTab: 'edit'
                                    }
                                });
                            } else {
                                this.step = 2;
                            }
                        }).catch(() => {
                        });
                    }).catch(() => {});
                } else {
                    this.isSavingBasic = false;
                }
            },

            addAnswer() {
                this.isSavingAnswer = true;
                this.$refs.formAnswer.validate();

                if (this.formAnswerValid) {
                    const answer = _.cloneDeep(this.answer);

                    this.$store.dispatch('poll/newAnswer', {
                        id: this.id,
                        answer
                    }).then(() => {
                        this.$store.dispatch('poll/getAnswers', this.id).then(() => {
                            this.tempAnswers = _.cloneDeep(this.answers);
                            this.$refs.formAnswer.reset();
                            this.isSavingAnswer = false;
                        }).catch(() => {});
                    }).catch(() => {});
                } else {
                    this.isSavingAnswer = false;
                }
            },

            deleteAnswer(answerId) {
                this.$store.dispatch('poll/deleteAnswer', {
                    id: this.id,
                    answerId
                }).then(() => {
                    this.$store.dispatch('poll/getAnswers', this.id).then(() => {
                        this.tempAnswers = _.cloneDeep(this.answers);
                    }).catch(() => {});
                }).catch(() => {});
            }
        }
    }
</script>

<style lang="scss">
</style>
